/* eslint-disable react/prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Flex, Box } from "@rebass/grid/dist/emotion"

import { H3 } from "./StyledHeaders"
import Button from "./Button"
import css from "@emotion/css"

const NonStretchedImage = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: 320,
        margin: "0 auto", // Used to center the image
      },
    }
  }

  return <Img {...normalizedProps} />
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 512) {
        ...GatsbyImageSharpFluid
        presentationWidth
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    idea: file(relativePath: { eq: "idea.png" }) {
      ...fluidImage
    }
    website: file(relativePath: { eq: "website.png" }) {
      ...fluidImage
    }
    startup: file(relativePath: { eq: "startup.png" }) {
      ...fluidImage
    }
    maintenance: file(relativePath: { eq: "maintenance.png" }) {
      ...fluidImage
    }
  }
`

const Dot = styled.div`
  margin-top: 16px;
  height: 28px;
  width: 28px;
  background: #ddd;
  border-radius: 100%;
  cursor: pointer;
  transition: all 300ms;

  ${props =>
    props.active &&
    css`
      background: ${props.theme.colors.secondary.main};
    `}
`

const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div:first-of-type {
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    transition: all 300ms;

    ${props =>
      props.active &&
      css`
        color: ${props.theme.colors.secondary.main};
      `}
  }
`

const Step = ({ label, active, handleClick }) => {
  return (
    <StyledStep active={active}>
      <div onClick={handleClick}>{label}</div>
      <Dot active={active} onClick={handleClick} />
    </StyledStep>
  )
}

Step.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
}

const StyledStepsWrapper = styled.div`
  max-width: 60%;
  margin: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-width: 100%;
  }
`

const StyledSteps = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  position: relative;
`

const StyledLine = styled.div`
  position: absolute;
  height: 4px;
  background: #ddd;
  top: 75%;
  left: 40px;
  right: 30px;
  z-index: -1;
`

const StyledDescription = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.text.secondary};
  margin: 8px 0 32px 0;
`

const Steps = ({ uri }) => {
  const [currStep, setStep] = useState(0)
  const data = useStaticQuery(pageQuery)

  const handleImage = () => {
    switch (currStep) {
      case 0:
        return <NonStretchedImage {...data.idea.childImageSharp} />
      case 1:
        return <NonStretchedImage {...data.website.childImageSharp} />
      case 2:
        return <NonStretchedImage {...data.startup.childImageSharp} />
      case 3:
        return <NonStretchedImage {...data.maintenance.childImageSharp} />

      default:
        return null
    }
  }

  const handleBody = () => {
    switch (currStep) {
      case 0:
        return (
          <>
            <H3 secondary>Prototyping & Ideation</H3>
            <StyledDescription>
              Our developers will understand your idea and devise a strategy to
              meet the expectations of your project to ensure the quality,
              efficiency and scalability of the application to be developed.
            </StyledDescription>
          </>
        )
      case 1:
        return (
          <>
            <H3 secondary>Coding</H3>
            <StyledDescription>
              Once the work methodology has been developed and the project
              specifications have been understood, our developers will begin to
              carry out the plan by developing the necessary code for your
              project.
            </StyledDescription>
          </>
        )
      case 2:
        return (
          <>
            <H3 secondary>Deployment</H3>
            <StyledDescription>
              Upon completion of the application development and coding process,
              it will be put to production tests to ensure the proper
              functioning of all modules of your application. Once the testing
              phase is finished we can carry out the production phase of your
              application.
            </StyledDescription>
          </>
        )
      case 3:
        return (
          <>
            <H3 secondary>Maintenance</H3>
            <StyledDescription>
              Any application that is already operating in the production phase
              requires the correct monitoring of actions and errors within it.
              The technology advances at an uncontrollable speed, that is why a
              good process of maintaining an application is essential for its
              success.
            </StyledDescription>
          </>
        )

      default:
        return null
    }
  }

  return (
    <StyledStepsWrapper>
      <StyledSteps>
        <StyledLine />
        <Step
          label={"Prototyping"}
          active={currStep === 0}
          handleClick={() => setStep(0)}
        />
        <Step
          label={"Build"}
          active={currStep === 1}
          handleClick={() => setStep(1)}
        />
        <Step
          label={"Deploy"}
          active={currStep === 2}
          handleClick={() => setStep(2)}
        />
        <Step
          label={"Mantain"}
          active={currStep === 3}
          handleClick={() => setStep(3)}
        />
      </StyledSteps>

      <Flex mt={4} mx={[-1, -3]} flexWrap="wrap">
        <Box width={[1, 1 / 2]} px={[1, 3]} my={[2]} flex="1 1 auto">
          {handleImage()}
        </Box>
        <Box
          width={[1, 1 / 2]}
          px={[1, 3]}
          my={[2]}
          flex="1 1 auto"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          {handleBody()}
          {currStep !== 3 ? (
            <Button
              outline={currStep !== 3}
              primary={currStep === 3}
              onClick={() => {
                if (currStep < 3) {
                  setStep(currStep + 1)
                }
              }}
            >
              Next Step &gt;
            </Button>
          ) : (
            <Button as={Link} to={`${uri}#contact-us`} primary="true">
              Get a quote
            </Button>
          )}
        </Box>
      </Flex>
    </StyledStepsWrapper>
  )
}

export default Steps
