/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '@rebass/grid/emotion';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Container from '../../components/Container';
import Hero from '../../components/Hero';
import { H1, H2 } from '../../components/StyledHeaders';
import StandardSection from '../../components/StandardSection';
import css from '@emotion/css';
import Button from '../../components/Button';
import HowWeWork from '../../components/HowWeWork';
import NonStretchedImage from '../../utils/NonStretchedImages';

const HiddenBox = styled(Box)`
  display: block;
  ${(props) => props.smDown && css`@media (max-width: ${props.theme.breakpoints[0]}) {display: none;}`}
`;

const WebDevelopment = (props) => {
	const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "web-development.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2560, quality: 100) {
						...GatsbyImageSharpFluid
						presentationWidth
					}
				}
			}
			sideImage: file(relativePath: { eq: "coding.png" }) {
				childImageSharp {
					fluid(maxWidth: 512, quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Web Development" />

			<Hero height={[ '40vh', '50vh' ]}>
				<Container>
					<div style={{ maxWidth: 720 }}>
						<H1>Web Development</H1>
					</div>
				</Container>
				<NonStretchedImage {...data.placeholderImage.childImageSharp} />
			</Hero>

			<StandardSection>
				<Container>
					<Flex flexWrap="wrap" mx={[ -2, -3 ]} py={'60px'}>
						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<H2>Our technology</H2>
							<p style={{ fontSize: 16, textAlign: 'justify' }}>
								Create your modern web application. We develop the best product with the methods that
								are revolutionizing the industry. Our experience and focus on web application
								development puts us in a unique position for the development of your personalized web
								application.
							</p>
						</Box>
						<HiddenBox width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto" />

						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<Img
								fluid={data.sideImage.childImageSharp.fluid}
								style={{ width: 320, margin: '0 auto' }}
							/>
						</Box>
						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<H2>What do we do?</H2>
							<p style={{ fontSize: 16, textAlign: 'justify' }}>
								We have a highly technical team of JavaScript experts. We are at the forefront of the
								newest and fastest evolving frameworks. Your company can rest assured that our
								continuous delivery approach will help you reach the market faster at a quarter of the
								cost and allow you to find your best product and market adjustment.
							</p>
						</Box>

						<Box width={[ 1 ]} px={[ 1, 3 ]} style={{ textAlign: 'center' }}>
							<Button primary="true" as={Link} to={`${props.uri}#contact-us`}>
								Request more info
							</Button>
						</Box>
					</Flex>
				</Container>

				<HowWeWork uri={props.uri} />
			</StandardSection>
		</Layout>
	);
};

export default WebDevelopment;
