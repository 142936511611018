import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const NonStretchedImage = (props) => {
	let normalizedProps = props;
	if (props.fluid && props.fluid.presentationWidth) {
		normalizedProps = {
			...props,
			style: {
				...(props.style || {}),
				maxWidth: props.fluid.presentationWidth,
				margin: '0 auto', // Used to center the image
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0
			}
		};
	}

	return <Img {...normalizedProps} />;
};

NonStretchedImage.propTypes = {
	fluid: PropTypes.object,
	style: PropTypes.object
};

export default NonStretchedImage;
