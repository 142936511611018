import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid/emotion';
import { H2 } from './StyledHeaders';
import Steps from './Steps';
import StandardSection from './StandardSection';

const StyledHowWorksP = styled.p`
	font-size: 16px;
	text-align: center;
	max-width: 50%;
	margin: auto;

	@media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
		max-width: 100%;
		text-align: justify;
	}
`;

const HowWeWork = ({ uri }) => {
	return (
		<StandardSection withBackground>
			<Box pt={[ '60px', '80px' ]} width={[ 1 ]} px={[ 1, 3 ]} my={[ 1, 2 ]}>
				<H2 center>How we work</H2>
				<StyledHowWorksP>
					Starting as you have an idea, we stay there to ensure the successful outcome for each phase of the
					software development process.
				</StyledHowWorksP>

				<Steps uri={uri} />
			</Box>
		</StandardSection>
	);
};

HowWeWork.propTypes = {
	uri: PropTypes.string
};

export default HowWeWork;
